import React from "react";
import SlasImage from "../../assets/slas.jpg";

const Slas = ({ location }: { location: Location }) => {
  const params = [...new URLSearchParams(location.search).entries()].reduce(
    (q, [k, v]) => Object.assign(q, { [k]: v }),
    {}
  );

  return (
    <div>
      <img src={SlasImage} />
      <p>När jag var liten, bodde det en man på andra sidan skogen.</p>
      <p>
        Han förstod inte varför Gud i himmelen som var så god, hade skapat:{" "}
        {params.b?.split(",").map((thing: string) => `${thing}, `)}
      </p>
      <p>När man kan {params.g}</p>
    </div>
  );
};

export default Slas;
